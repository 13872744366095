















































































import { LoadingStatusType, TableSorting } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { MerchantDetailForVerifyMerchantRequest, MerchantForVerifyList } from "@/model/merchant/merchantModel";
import VerifyMerchantActionList from "@/views/merchant/verifyMerchant/VerifyMerchantActionList.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");

@Component({ components: { VerifyMerchantActionList } })
export default class MerchantForVerifyTable extends Vue {
  private textSearchTaxId = "";
  private table: any = {
    header: [],
    detail: [],
  };
  private action: any = [{ actionValue: 1, text: "แก้ไข" }];
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];

  @StoreMerchant.Getter
  private getMerchantForVerifyList!: Array<MerchantForVerifyList>;
  @StoreMerchant.Getter
  private getHeaderMerchantForVerifyTable!: Array<any>;
  @StoreMerchant.Getter
  private getCountMerchantListForVerify!: number;
  @StoreMerchant.Getter
  private getMerchantListForVerifyLoadingStatus!: LoadingStatusType;
  @StoreMerchant.Getter
  private getUpdateVerificationStatusLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoGetMerchantListForVerify!: (input: MerchantDetailForVerifyMerchantRequest) => void;

  @Watch("getMerchantListForVerifyLoadingStatus", { immediate: true })
  getPromoCodeListLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.header = NewObject(this.getHeaderMerchantForVerifyTable);
      this.table.detail = this.getMerchantForVerifyList.length > 0 ? NewObject(this.getMerchantForVerifyList) : [];
      this.countItem = this.getCountMerchantListForVerify;
      this.totalPage = Math.ceil(this.countItem / this.pageSize);
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetMerchantForVerifyTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetMerchantForVerifyTable(1, this.pageSize);
  }

  @Watch("getUpdateVerificationStatusLoadingStatus")
  getUpdateVerificationStatusChanged(newVal: number) {
    this.GetMerchantForVerifyTable(this.pageNo, this.pageSize);
  }

  public GetMerchantForVerifyTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    if (this.textSearchTaxId.trim().length > 0) {
      const verifyMerchantRequest: MerchantDetailForVerifyMerchantRequest = {
        pageNo: pageNo,
        pageSize: pageSize,
        tableSorting: TableSorting.ASC,
        taxId: this.textSearchTaxId.trim(),
      };
      this.DoGetMerchantListForVerify(verifyMerchantRequest);
    }
  }

  public SearchMerchant() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.GetMerchantForVerifyTable(this.pageNo, this.pageSize);
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }

  public DownLoadLogo(logoUrl: string) {
    window.open(logoUrl);
  }
}
