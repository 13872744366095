

































import { Component, Vue, Prop } from "vue-property-decorator";
import UpdateVerificationStatusMerchant from "./UpdateVerificationStatusMerchant.vue";

@Component({ components: { UpdateVerificationStatusMerchant } })
export default class VerifyMerchantActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;

  private verifyMerchantAction = [{ text: "Update Status", icon: "", actionValue: 1 }];
  private isShowList = false;
  private isShowUpdateVerificationStatusMerchant = false;

  public SetShowActionList(newValue: boolean) {
    this.isShowList = newValue;
  }

  public SetShowModalAction(actionValue: number) {
    if (actionValue === 1) {
      this.isShowUpdateVerificationStatusMerchant = true;
    }
  }
}
