






import { Component, Vue } from "vue-property-decorator";
import VerifyMerchantTable from "./VerifyMerchantTable.vue";

@Component({ components: { VerifyMerchantTable } })
export default class VerifyMerchant extends Vue {}
