































import { VerifiedStatus } from "@/enums/enums";
import { MerchantForVerifyList, UpdateVerificationStatusMerchantRequest } from "@/model/merchant/merchantModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");

@Component
export default class UpdateVerificationStatusMerchant extends Vue {
  @Prop({ required: true }) readonly item!: MerchantForVerifyList;

  private showModal = true;
  private verificationStatusList = [
    { value: VerifiedStatus.None, text: "None" },
    { value: VerifiedStatus.Processing, text: "Processing" },
    { value: VerifiedStatus.Success, text: "Success" },
  ];
  private verificationStatus = VerifiedStatus.None;

  created() {
    this.SetSelectVerificationStatus();
  }

  @StoreMerchant.Action
  private DoUpdateVerificationStatusMerchant!: (
    updateVerificationStatus: UpdateVerificationStatusMerchantRequest
  ) => void;

  @Watch("showModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.HideModal();
    }
  }

  public SetSelectVerificationStatus() {
    this.verificationStatus = this.item.verifiedStatus;
  }

  public UpdateVerificationStatus() {
    const input = {
      merchantId: this.item.merchantId,
      taxId: this.item.taxId,
      branchCode: this.item.branchCode,
      verifiedStatus: this.verificationStatus,
    };
    this.DoUpdateVerificationStatusMerchant(input);
    this.HideModal();
  }

  public HideModal() {
    this.$emit("update:showModal", false);
  }
}
